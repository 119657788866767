<template>
	<!-- 七牛云设备列表 -->
	<div class="live-monitor-list el-content">
		<div class="tips">
			<p>国标设备用于支持国标(GB/T28181)第三方监控品牌摄像头，例如：海康、大华、宇视等品牌</p>
			<p>计费方式：设备管理费50元/年/台（NVR按通道计费），流量/带宽：上行0.3元/G,下行0.8元/G（按需付费）</p>
			<p>上行：指设备端接入产生的带宽或流量；下行：指的是用户实时预览产生的带宽或流量</p>
		</div>
		<a-button class="mb12" type="primary" v-has="{action:'wxapp_live_monitor_add',plat:0}" @click="monitorEdit(0)">
			<i class="ri-add-line"></i>新增设备
		</a-button>
		<a-table :pagination="false" row-key="gbId" :data-source="info.list" :columns="[
			{title:'设备ID',dataIndex:'gbId'},
			{title:'类型',dataIndex:'type',slots:{customRender:'type'}},
			{title:'设备名称',dataIndex:'desc'},
			{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'通道数',dataIndex:'channels'},
			{title:'创建时间',dataIndex:'end_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]">
			<template #type="{record}">
				<span>{{ record.type == 1 ?'摄像头':'平台'}}</span>
			</template>
			<template #status="{record}">
				<a-tag color="#999" v-if="record.state == 'offline'">离线</a-tag>
				<a-tag color="#00CC66" v-if="record.state == 'online'">在线</a-tag>
				<a-tag color="#FF9900" v-if="record.state == 'notReg'">未注册</a-tag>
				<a-tag color="#FF0066" v-if="record.state == 'locked'">锁定</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/live/monitor_detail',query:{id:record.id}}">
						<a-button size="small" type="link" v-has="{action:'/live/monitor_detail',plat:0}">查看 </a-button>
					</router-link>	
					<router-link :to="{path:'/live/monitor_detail',query:{id:record.id,active:'second'}}">
						<a-button size="small" type="link" v-has="{action:'/live/monitor_detail',plat:0}">实时预览</a-button>
					</router-link>
					<router-link :to="{path:'/live/monitor_detail',query:{id:record.id,active:'third'}}">
						<a-button size="small" type="link" v-has="{action:'/live/monitor_detail',plat:0}" v-if="record.type == 2">通道列表</a-button>
					</router-link>
					
					<a-button size="small" type="link" v-has="{action:'/live/monitor_detail',plat:0}" @click="toDel(record.id)">删除</a-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
			    show-size-changer
			    :default-current="info.page"
			    :total="info.count"
			    @showSizeChange="(p,e)=>{getMonitorList(info.page,e)}"
				@change="(e)=>{getMonitorList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.edit_qiniu" title="添加设备" @ok="monitorSave" width="500px" @cancel="show.edit_qiniu = false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="设备类型">
					<a-radio-group v-model:value="qiniuForm.type">
						<a-radio value="1">摄像头</a-radio>
						<a-radio value="2">平台（NVR）</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="设备名称">
					<a-input v-model:value="qiniuForm.desc" placeholder="请输入设备名称"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="show.success" title="添加成功" :footer="null" width="500px" @cancel="show.success = false">
			<div class="monitor-sucess">
				<div class="ms-icon"><i class="ri-check-line ri-2x"></i></div>
				<span>摄像头创建成功</span>
			</div>
			<div class="ms-step" >
				<a-steps direction="vertical" size="small" :current="2">
					<a-step title="摄像头配置">
						<template #description>
							<p>您还需要前往摄像头/平台NVR管理后端，配置网关信息，完成后才能对设备和流进行管理和监控</p>
							<a href="https://www.yuque.com/kundian/ipc/emqkfx" target="__blank">
								<a-button class="mb12">如何配置？</a-button>
							</a>
						</template>
					</a-step>
					<a-step title="空间创建成功">
						<template #description>
							<a-button type="primary" style="margin-top:12px" @click="show.success = false">返回查看设备</a-button>
						</template>
					</a-step>
				</a-steps>
			</div>
		</a-modal>
		
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import liveModel from '@/api/live';
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{
				edit_qiniu:false,
				success:false
			},
			qiniuForm:{
				type:"1",
				desc:"",
			}
		})
		getMonitorList(1,_d.info.limit)
		function getMonitorList( page,limit ){
			liveModel.getGbDevice(page,limit,null,res=>_d.info ={limit,...res})
		}

		function monitorEdit(data){
			_d.qiniuForm.id = data ? data.id :0
			_d.qiniuForm.name = data ? data.name :""
			_d.qiniuForm.desc = data ? data.desc :""
			_d.show.edit_qiniu = true
		}

		const monitorSave = ()=>liveModel.addOrEditGbDevice(_d.qiniuForm,()=>{
			_d.show.edit_qiniu = false
			_d.show.success = true
			getMonitorList(_d.info.page,_d.info.limit)
		})

		const toDel = id=>liveModel.deleteGbDevice(id,()=>getMonitorList(_d.info.page,_d.info.limit))

		return{
			...toRefs(_d),
			getMonitorList,
			monitorEdit,
			monitorSave,
			toDel
		}
	}
}
</script>

<style scoped lang="scss">
.monitor-sucess{
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	.ms-icon{
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		background: #00CC66;
		color: #fff;
		border-radius: 50%;
		margin-right: 12px;
	}
	>span{
		font-size: 18px;
	}
}
.ms-step{
	margin-left: 10px;
}
</style>
